import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { RiCheckboxBlankFill } from "react-icons/ri"
import Modal from "react-modal"
import "whatwg-fetch"

import "../assets/components/bootstrap/css/bootstrap.min.css"

import "../assets/scss/theme.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Arrow from "../../static/images/arrow.svg"
import Anual from "../../static/images/anual.svg"
import CardIcon from "../../static/images/card.svg"
import Flash from "../../static/images/flash.svg"
import Check from "../../static/images/check.svg"
import Spending from "../../static/images/spending.svg"
import Exchange from "../../static/images/x_change.svg"

Modal.setAppElement(`#___gatsby`)

const App = props => {
  const query = useStaticQuery(graphql`
    query {
      couple: file(relativePath: { eq: "couple11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      coupleThinner: file(relativePath: { eq: "couple11_2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  if (props.location && props.location.search !== "") {
    var searchParams = new URLSearchParams(props.location.search)
    if (
      searchParams.has("message") &&
      searchParams.get("message").indexOf("Your email was verified.") >= 0
    ) {
      window.history.replaceState("", document.title, window.location.pathname)
      window.location.replace(
        "/app/sign-in"
      )
    }
  }

  const [message, setMessage] = React.useState("")

  const closeMessageModal = () => {
    setMessage("")
  }

  var messageModalOpen = message !== ""

  return (
    <Layout>
      <SEO title="Home" />
      <header className="top-of-page" id="top">
        <div className="position-relative header-container">
          <div className="position-relative">
            {/* <Img
              fluid={[
                query.topMob2.childImageSharp.fluid,
                {
                  ...query.top.childImageSharp.fluid,
                  media: `(min-width: 551px)`,
                },
                {
                  ...query.topMob.childImageSharp.fluid,
                  media: `(min-width: 400px)`,
                }
              ]}
              className="w-100"
              style={{ minHeight: 450, maxHeight: 700 }}
            /> */}
            <div
              className="w-100 gatsby-image-wrapper"
              style={{
                position: "relative",
                overflow: "hidden",
                minHeight: 450,
                maxHeight: 700,
              }}
            >
              <div
                aria-hidden="true"
                style={{ width: "100%", paddingBottom: "37.5%" }}
              ></div>
              <picture>
                <source
                  media="(min-width: 551px)"
                  srcSet="data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAQC/9oADAMBAAIQAxAAAAHBMOi8Hv8A/8QAGRAAAwADAAAAAAAAAAAAAAAAAQIRAxIh/9oACAEBAAEFAlenaHH1P//EABgRAAIDAAAAAAAAAAAAAAAAAAABESEx/9oACAEDAQE/AZoWH//EABcRAQEBAQAAAAAAAAAAAAAAAAEAAjH/2gAIAQIBAT8BQtdv/8QAGRAAAgMBAAAAAAAAAAAAAAAAACECETFB/9oACAEBAAY/Aq4LBSP/xAAZEAEAAwEBAAAAAAAAAAAAAAABABEhMXH/2gAIAQEAAT8h1EUIJbqywOHk/9oADAMBAAIAAwAAABADz//EABYRAQEBAAAAAAAAAAAAAAAAAAERAP/aAAgBAwEBPxCmnGjf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EN0kZf/EABkQAQADAQEAAAAAAAAAAAAAAAEAESExQf/aAAgBAQABPxBhHxkbihXObAKAWVXNn//Z"
                />
                <source
                  media="(min-width: 400px)"
                  srcSet="data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCA//EABYBAQEBAAAAAAAAAAAAAAAAAAQBAv/aAAwDAQACEAMQAAABlcslTSmTP//EABkQAQEAAwEAAAAAAAAAAAAAAAECABEiIf/aAAgBAQABBQI6PIC5cnYPRN6P/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAES/9oACAEDAQE/Aa0//8QAFhEBAQEAAAAAAAAAAAAAAAAAABES/9oACAECAQE/AYw//8QAFxAAAwEAAAAAAAAAAAAAAAAAAAEhEP/aAAgBAQAGPwITZMpD/8QAHBABAAICAwEAAAAAAAAAAAAAAQARIWExQVGR/9oACAEBAAE/IQIu5WQILY+kfIjobY87mCDW2f/aAAwDAQACAAMAAAAQqx//xAAYEQADAQEAAAAAAAAAAAAAAAAAAREhUf/aAAgBAwEBPxBlLNF0z//EABgRAAMBAQAAAAAAAAAAAAAAAAABESEx/9oACAECAQE/EO5cG1xH/8QAHBABAAMAAgMAAAAAAAAAAAAAAQARITFBYYHB/9oACAEBAAE/ENAEoX1xLMUMod9EwBOKsYqqiiwEYQ6LV5h48e35P//Z"
                />
                <source srcSet="data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAXABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBAX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAT/2gAMAwEAAhADEAAAAcVs8uTTtcxG6I6AY//EABsQAAICAwEAAAAAAAAAAAAAAAECABEDEiIQ/9oACAEBAAEFAte1HWo8yPZBaXTkCbKJ/8QAGBEBAQADAAAAAAAAAAAAAAAAAAEREiH/2gAIAQMBAT8BvGysv//EABoRAQABBQAAAAAAAAAAAAAAAAABAhETIVH/2gAIAQIBAT8B3Uxz1Cz/xAAZEAACAwEAAAAAAAAAAAAAAAAAARAhMTL/2gAIAQEABj8CNmjYbbOT/8QAGxAAAwEAAwEAAAAAAAAAAAAAAAERIUFRYaH/2gAIAQEAAT8hcQuDQm15rOxjxt4Womb6SZ9Delo7STYsSv1n/9oADAMBAAIAAwAAABBg0Pz/xAAZEQADAAMAAAAAAAAAAAAAAAAAAREhMVH/2gAIAQMBAT8QVZk8H2Uf/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERITH/2gAIAQIBAT8QXAgE1En/xAAcEAEAAgMBAQEAAAAAAAAAAAABABEhMUFhcZH/2gAIAQEAAT8QJzaBz2NY961PKORF6TNLK6DLDElaLv1FZwX4m5QbLidPSAXP0jcs/9k=" />
                <img
                  aria-hidden="true"
                  src="data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAIDBP/EABUBAQEAAAAAAAAAAAAAAAAAAAQC/9oADAMBAAIQAxAAAAHBMOi8Hv8A/8QAGRAAAwADAAAAAAAAAAAAAAAAAQIRAxIh/9oACAEBAAEFAlenaHH1P//EABgRAAIDAAAAAAAAAAAAAAAAAAABESEx/9oACAEDAQE/AZoWH//EABcRAQEBAQAAAAAAAAAAAAAAAAEAAjH/2gAIAQIBAT8BQtdv/8QAGRAAAgMBAAAAAAAAAAAAAAAAACECETFB/9oACAEBAAY/Aq4LBSP/xAAZEAEAAwEBAAAAAAAAAAAAAAABABEhMXH/2gAIAQEAAT8h1EUIJbqywOHk/9oADAMBAAIAAwAAABADz//EABYRAQEBAAAAAAAAAAAAAAAAAAERAP/aAAgBAwEBPxCmnGjf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EN0kZf/EABkQAQADAQEAAAAAAAAAAAAAAAEAESExQf/aAAgBAQABPxBhHxkbihXObAKAWVXNn//Z"
                  alt=""
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center center",
                    opacity: 0,
                    transitionDelay: "500ms",
                  }}
                />
              </picture>
              <picture>
                <source
                  type="image/webp"
                  media="(min-width: 551px)"
                  srcSet="/images/static/04ab64352dddc188161c0947075f3a7f/61be4/CN_index2.webp 480w,
/images/static/04ab64352dddc188161c0947075f3a7f/1fb14/CN_index2.webp 960w,
/images/static/04ab64352dddc188161c0947075f3a7f/8e7a0/CN_index2.webp 1871w"
                  sizes="(max-width: 1871px) 100vw, 1871px"
                />
                <source
                  media="(min-width: 551px)"
                  srcSet="/images/static/04ab64352dddc188161c0947075f3a7f/9104c/CN_index2.jpg 480w,
/images/static/04ab64352dddc188161c0947075f3a7f/a6352/CN_index2.jpg 960w,
/images/static/04ab64352dddc188161c0947075f3a7f/71d21/CN_index2.jpg 1871w"
                  sizes="(max-width: 1871px) 100vw, 1871px"
                />
                <source
                  type="image/webp"
                  media="(min-width: 400px)"
                  srcSet="/images/static/cc39073d0e09a3049a403a97780e63bd/61be4/CN_index_mobile.webp 480w,
/images/static/cc39073d0e09a3049a403a97780e63bd/1fb14/CN_index_mobile.webp 960w,
/images/static/cc39073d0e09a3049a403a97780e63bd/fdd1b/CN_index_mobile.webp 967w"
                  sizes="(max-width: 967px) 100vw, 967px"
                />
                <source
                  media="(min-width: 400px)"
                  srcSet="/images/static/cc39073d0e09a3049a403a97780e63bd/9104c/CN_index_mobile.jpg 480w,
/images/static/cc39073d0e09a3049a403a97780e63bd/a6352/CN_index_mobile.jpg 960w,
/images/static/cc39073d0e09a3049a403a97780e63bd/8e4fe/CN_index_mobile.jpg 967w"
                  sizes="(max-width: 967px) 100vw, 967px"
                />
                <source
                  type="image/webp"
                  srcSet="/images/static/94c588bb769e22da809664a8e7a251c4/61be4/CN_index_mobile2.webp 480w,
/images/static/94c588bb769e22da809664a8e7a251c4/8d5db/CN_index_mobile2.webp 618w"
                  sizes="(max-width: 618px) 100vw, 618px"
                />
                <source
                  srcSet="/images/static/94c588bb769e22da809664a8e7a251c4/9104c/CN_index_mobile2.jpg 480w,
/images/static/94c588bb769e22da809664a8e7a251c4/211c4/CN_index_mobile2.jpg 618w"
                  sizes="(max-width: 618px) 100vw, 618px"
                />
                <img
                  sizes="(max-width: 1871px) 100vw, 1871px"
                  srcSet="/images/static/04ab64352dddc188161c0947075f3a7f/9104c/CN_index2.jpg 480w,
/images/static/04ab64352dddc188161c0947075f3a7f/a6352/CN_index2.jpg 960w,
/images/static/04ab64352dddc188161c0947075f3a7f/71d21/CN_index2.jpg 1871w"
                  src="/images/static/04ab64352dddc188161c0947075f3a7f/71d21/CN_index2.jpg"
                  alt=""
                  loading="lazy"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center center",
                    opacity: 1,
                    transition: "opacity 500ms ease 0s",
                  }}
                />
              </picture>
              <noscript>
                <picture>
                  <source
                    type="image/webp"
                    media="(min-width: 551px)"
                    srcSet="/images/static/04ab64352dddc188161c0947075f3a7f/61be4/CN_index2.webp 480w,
/images/static/04ab64352dddc188161c0947075f3a7f/1fb14/CN_index2.webp 960w,
/images/static/04ab64352dddc188161c0947075f3a7f/8e7a0/CN_index2.webp 1871w"
                    sizes="(max-width: 1871px) 100vw, 1871px"
                  />
                  <source
                    media="(min-width: 551px)"
                    srcSet="/images/static/04ab64352dddc188161c0947075f3a7f/9104c/CN_index2.jpg 480w,
/images/static/04ab64352dddc188161c0947075f3a7f/a6352/CN_index2.jpg 960w,
/images/static/04ab64352dddc188161c0947075f3a7f/71d21/CN_index2.jpg 1871w"
                    sizes="(max-width: 1871px) 100vw, 1871px"
                  />
                  <source
                    type="image/webp"
                    media="(min-width: 400px)"
                    srcSet="/images/static/cc39073d0e09a3049a403a97780e63bd/61be4/CN_index_mobile.webp 480w,
/images/static/cc39073d0e09a3049a403a97780e63bd/1fb14/CN_index_mobile.webp 960w,
/images/static/cc39073d0e09a3049a403a97780e63bd/fdd1b/CN_index_mobile.webp 967w"
                    sizes="(max-width: 967px) 100vw, 967px"
                  />
                  <source
                    media="(min-width: 400px)"
                    srcSet="/images/static/cc39073d0e09a3049a403a97780e63bd/9104c/CN_index_mobile.jpg 480w,
/images/static/cc39073d0e09a3049a403a97780e63bd/a6352/CN_index_mobile.jpg 960w,
/images/static/cc39073d0e09a3049a403a97780e63bd/8e4fe/CN_index_mobile.jpg 967w"
                    sizes="(max-width: 967px) 100vw, 967px"
                  />
                  <source
                    type="image/webp"
                    srcSet="/images/static/94c588bb769e22da809664a8e7a251c4/61be4/CN_index_mobile2.webp 480w,
/images/static/94c588bb769e22da809664a8e7a251c4/8d5db/CN_index_mobile2.webp 618w"
                    sizes="(max-width: 618px) 100vw, 618px"
                  />
                  <source
                    srcSet="/images/static/94c588bb769e22da809664a8e7a251c4/9104c/CN_index_mobile2.jpg 480w,
/images/static/94c588bb769e22da809664a8e7a251c4/211c4/CN_index_mobile2.jpg 618w"
                    sizes="(max-width: 618px) 100vw, 618px"
                  />
                  <img
                    loading="lazy"
                    sizes="(max-width: 1871px) 100vw, 1871px"
                    srcSet="/images/static/04ab64352dddc188161c0947075f3a7f/9104c/CN_index2.jpg 480w,
/images/static/04ab64352dddc188161c0947075f3a7f/a6352/CN_index2.jpg 960w,
/images/static/04ab64352dddc188161c0947075f3a7f/71d21/CN_index2.jpg 1871w"
                    src="/images/static/04ab64352dddc188161c0947075f3a7f/71d21/CN_index2.jpg"
                    alt=""
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center center",
                      opacity: 1,
                    }}
                  />
                </picture>
              </noscript>
            </div>
          </div>
          <div
            className="position-absolute p-top p-right p-left p-bottom d-flex justify-content-between flex-column"
            style={{ zIndex: 2 }}
          >
            <div className="container pt-6 pt-md-10 pt-xxl-18">
              <div className="onimage-text-container">
                <h1 className="mb-3">Keeping you in the black</h1>
                <div className="fs-lg mb-4 mb-xxl-8 text-white">
                  The Card Noir Prepaid Visa brings you a bankcard without the
                  need for a bank.
                </div>
                <h1 className="text-primary">
                  <Arrow className="mr-2 mb-1 coming-autum-arrow" />
                  Available Now!
                </h1>
              </div>
            </div>
            <div className="container pb-10 pb-xxl-18 text-center text-md-left">
              <a
                className="btn btn-lg btn-rounded-rectangle btn-outline-primary px-12"
                href="/app/sign-up"
              >
                SIGN UP
              </a>
            </div>
          </div>
          <div className="svg-card-holder d-none d-md-block">
            <div className="fixer">
              <picture>
                <source
                  srcSet="images/CN_card_Visa2_resized-min.png"
                  media="(min-width: 768px)"
                />
                <img
                  className="card-holder"
                  src="images/CN_card_Visa_blur.png"
                  alt=""
                />
              </picture>
              {/* <img
                className="card-holder"
                src="images/CN_card_Visa2.png"
                alt=""
              /> */}
            </div>
          </div>
        </div>
      </header>

      <main className="main-container pt-2" id="about">
        <section id="features">
          <div className="container">
            <div className="d-md-flex mb-6 text-center justify-content-center flex-wrap-reverse fs-lg">
              <div className="mx-2">
                <RiCheckboxBlankFill className="fs-md text-primary mr-1 pb-1" />
                No issuing fee
              </div>
              <div className="mx-2">
                <RiCheckboxBlankFill className="fs-md text-primary mr-1 pb-1" />
                No credit checks
              </div>
              <div className="mx-2">
                <RiCheckboxBlankFill className="fs-md text-primary mr-1 pb-1" />
                High spending limit
              </div>
              <div className="mx-2">
                <RiCheckboxBlankFill className="fs-md text-primary mr-1 pb-1" />
                Quick online application
              </div>
            </div>
          </div>
          <div className="container">
            <h2 className="text-primary text-center">
              What you get with your Card Noir card
            </h2>
            <div className="row mt-10">
              <div className="col-md-6 mb-4">
                <div className="d-flex flex-column text-center">
                  <Check className="wyg-icon" />
                  <div>
                    <h5>Simple, quick ordering</h5>
                    <p>
                      No credit checks, no income checks. Just request a card.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="d-flex flex-column text-center">
                  <Spending className="wyg-icon" />
                  <div>
                    <h5>High spending limit</h5>
                    <p>
                      Card Noir places more spending power in your hands, so you
                      can buy freely without ever spending money you don't have.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="d-flex flex-column text-center">
                  <Flash className="wyg-icon" />
                  <div>
                    <h5>Instant Transfer Between Cards</h5>
                    <p>
                      Need to send money to another Card Noir card holder? No
                      problem, just login to your account, enter the amount you
                      want to send, and they'll receive it immediately.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="d-flex flex-column text-center">
                  <Exchange className="wyg-icon" />
                  <div>
                    <h5>Low foreign exchange rates</h5>
                    <p>
                      Save when sending money abroad with cheaper rates for
                      international transactions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="d-flex flex-column text-center">
                  <Anual className="wyg-icon" />
                  <div>
                    <h5>No issuing fee</h5>
                    <p>
                      Unlike other prepaid cards, once you apply, the card is
                      yours. You only pay a minor annual fee.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="d-flex flex-column text-center">
                  <CardIcon className="wyg-icon" />
                  <div>
                    <h5>The best international Visa</h5>
                    <p>
                      Combine the above benefits together, and you have the
                      perfect alternative to cash, credit and debit cards... in
                      your hands.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="signup" className="section py-8">
          <div className="container-fluid text-center">
            <h1 className="text-primary">
              <Arrow className="mr-2 mb-1 coming-autum-arrow" />
              Available Now!
            </h1>
            <a
              className="btn btn-lg btn-rounded-rectangle btn-outline-primary px-12 mt-4"
              href="/app/sign-up"
            >
              SIGN UP
            </a>
          </div>
        </section>
        <section id="freedom">
          <div className="position-relative">
            <div className="position-relative">
              <Img
                className="w-100"
                fluid={[
                  query.couple.childImageSharp.fluid,
                  {
                    ...query.coupleThinner.childImageSharp.fluid,
                    media: `(min-width: 1400px)`,
                  },
                ]}
                style={{ minHeight: 450, maxHeight: 700 }}
              />
            </div>
            <div className="position-absolute p-top p-right p-left p-bottom d-flex justify-content-center flex-column">
              <div className="container">
                <div className="onimage-text-container text-center text-md-left">
                  <h1>Your card to freedom</h1>
                  <p className="fs-md mt-6">
                    Just add rules to your card using your online Card Noir
                    account and start spending, whether you are shopping or
                    withdrawing cash from ATM machines.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Modal
        isOpen={messageModalOpen}
        onRequestClose={closeMessageModal}
        style={{
          overlay: {
            backgroundColor: "rgba(75,75,75, 0.7)",
            zIndex: 1001,
          },
          content: {
            maxWidth: 600,
            marginRight: "auto",
            marginLeft: "auto",
          },
        }}
        shouldCloseOnOverlayClick={true}
        shouldFocusAfterRender={true}
        className="p-0"
        overlayClassName="position-fixed p-top p-bottom p-left p-right d-flex flex-column justify-content-center"
        contentLabel="Modal"
        closeTimeoutMS={0}
      >
        <div className="bg-secondary p-2 p-sm-4 px-md-11 py-md-8">
          <h3 className="text-primary m-0 mb-4">{message}</h3>

          <div className="text-right">
            <button
              className="btn btn-rounded-rectangle btn-outline-primary px-5 py-1 mt-4"
              onClick={closeMessageModal}
            >
              CLOSE
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  )
}

export default App
